<template>
  <div class="surface-section px-4 py-8 md:px-6 lg:px-0">
    <div
      style="
        background: radial-gradient(
          50% 109137.91% at 50% 50%,
          var(--base) 0%,
          rgba(254, 244, 247, 0) 100%
        );
      "
      class="text-center"
    >
      <span style="color: var(--base2)" class="bg-white   font-bold text-8xl inline-block px-8"
        >404</span
      >
    </div>
    <div class="mt-6 mb-5 font-bold text-6xl text-900 text-center">
      Страница не найдена
    </div>
    <p class="text-700 text-3xl mt-0 mb-6 text-center">
      Извините, мы не смогли найти страницу
    </p>
    <div class="text-center">
      <Button
        class="p-button-text mr-2"
        label="Назад"
        icon="pi pi-arrow-left"
        @click="$router.go(-1)"
      ></Button>
      <Button
        label="На Главную"
        icon="pi pi-briefcase"
        @click="$router.push('/')"
      ></Button>
    </div>
  </div>
</template>

<script>
import LayoutBase from "../components/layouts/LayoutBase";
export default {
  components: { LayoutBase },
};
</script>

<style>
</style>